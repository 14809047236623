// Gatsby requirements
import React, { useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Components
import RichTextRenderer from '../richTextRenderer'

// Styles
import * as CSS from './footer.module.scss'


const Footer = ({ en, previewData }) =>  {

    // Footer data query
    const data = useStaticQuery(graphql`
        query {
            footer: storyblokEntry(field_component: {eq: "Footer"}, slug: {eq: "footer"}) {
                content
            }
        }
    `)
    
    // Display "goToTop" after scrolling
    useEffect(() => {
        const myID = document.getElementById("goToTop");

        var myScrollFunc = function() {
        var y = window.scrollY;
        if (y >= 400) {
            myID.className = "show"
        } else {
            myID.className = "hide"
        }
        };

        window.addEventListener("scroll", myScrollFunc);
    }, []);

    // Footer content to parsed JSON
    const jsonData = previewData?.content || JSON.parse(data.footer.content);
    // const jsonDataEn = previewData?.content || JSON.parse(data.footer_en.content)


    return (
        <footer className={CSS.footer}>

            <a href="#top" id="goToTop" aria-label="Go to top"></a>

            <div className="wrapper">
                <div>
                    <Link style={{marginBottom: `${jsonData?.site_logo_bottom_margin}px`}} to={en === true ? '/en/' : '/'}>
                        <img 
                            style={{
                                height: `${jsonData?.footer_logo_height}px`
                            }}
                            src={jsonData?.footer_logo?.filename}
                            width={jsonData.footer_logo?.filename?.split('/')[5]?.split('x')[0]}
                            height={jsonData?.footer_logo?.filename?.split('/')[5]?.split('x')[1]}
                            alt={jsonData?.footer_logo?.alt} 
                        />
                    </Link>
                    <RichTextRenderer data={jsonData?.richtext} />
                </div>
                <div>
                    <RichTextRenderer data={jsonData?.second_area} />
                </div>
                <div>
                    <RichTextRenderer data={jsonData?.third_area} />
                </div>
                <div>
                    <RichTextRenderer data={jsonData?.fourth_area} />
                </div>
            </div>

            <div className="wrapper footer-bottom-text">
                <RichTextRenderer data={jsonData?.bottom_text} />
            </div>

        </footer>
    )
}

export default Footer