// Gatsby requirements
import React from "react"
import { graphql } from "gatsby"

// Storyblok preview
import { useStoryblokState } from "gatsby-source-storyblok"
import { storyblokInit } from "gatsby-source-storyblok"

// Components
import Footer from "components/layout/footer"


storyblokInit({
    accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
});

const FooterPreview = ({ data }) =>  {

    let story = data.footer;

    story = useStoryblokState(story)
    
    return (
      <Footer previewData={story} />
    )
}

export const Head = () => (
    <>
      <title>Footer Preview</title>
      <meta name="robots" content="noindex"/>
    </>
)

export const query = graphql`
  query {
    footer: storyblokEntry(field_component: {eq: "Footer"}, slug: {eq: "footer"}) {
        content
        id
        uuid
        internalId
    }
  }
`

export default FooterPreview